import React from "react"
import "../styles/footer.css"

export default function Footer() {
  return (
    <div className="footer-class">
      <div>
        <hr />
        {/* <h5>made with Gatsby.js and React.</h5> */}
        <h5 className="text-normal">© 2024 Matthew Bilik</h5>
      </div>
    </div>
  )
}
