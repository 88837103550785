import * as React from "react"
import Header from "../components/head"
import Footer from "../components/footer"
import { SEO } from "../components/seo"
import "@fontsource/newsreader"
import "@fontsource/inter/400.css"
import electionVideo from "../../static/election.mp4"
import { StaticImage } from "gatsby-plugin-image"

const Home = () => {
  return (
    <div className="all">
      <div className="container">
        <div className="column top-special">
          <Header />
          <h3>❀✿✾ |
          
            mbilik at <span className="highlighted">umich</span> dot edu
          </h3>
          <h5 className="text-normal">
            Hi – I’m a third-year at the University of Michigan. I am completing a{" "}
            <b>
              B.Sc. in computer science and sociology, and a minor in
              science, technology, and society studies.
            </b>{" "}
            My interests are technology policy, the history of science, social theory, 
            and comparative historical sociology.{" "}
            <a href="/papers"> Here are a few of my publications.</a> {" "}
            <b>
              I am the Data Editor at The Michigan Daily. I was previously a
              data journalist at The Daily where I was the summer Managing
              Online Editor in the summer of 2022.
            </b>
          </h5>
        </div>
      </div>

      <div className="work-section">
        <div>
          <h5 className="text-normal">
            Much of my software oriented work involves
            full-stack engineering.
          </h5>
        </div>
      </div>

      <div className="container">
        <div className="column iframeDisplay">
          <a href="https://specials.michigandaily.com/primary-22/">
            2022 Primary Election Voter Guide
          </a>
          <h5 className="text-normal">
            Built with React, ArchieML, and an AWS
            CloudFormation stack. Maps produced with D3, GDAL, Turfpy, and
            Folium.
          </h5>
          <video autoPlay loop>
            <source src={electionVideo} type="video/mp4" />
          </video>
        </div>
      </div>

      <div className="container">
        <div className="column iframeDisplay">
          <a href="https://www.michigandaily.com/news/more-than-100-ann-arbor-restaurants-closed-in-the-past-three-years-how-have-these-closures-affected-the-community/">
            More than 100 Ann Arbor restaurants closed in the past three years.
            How have these closures affected the community?
          </a>
          <h5 className="text-normal">
            Data scraped from Washtenaw County's restaurant inspection portal.
            Restaurant centroids repositioned with QGIS and mapped using
            building extent boundaries from Ann Arbor's open data portal. Part
            of a several month investigation into restaurant closures in Ann
            Arbor following the height of the COVID-19 pandemic. Won SPJ Mark of
            Excellence in Food and Restaurant Journalism.
          </h5>
          <iframe
            title="Restaurant closures in Ann Arbor"
            className="iframeDisplay"
            src="https://michigandaily.github.io/restaurant-viz/"
            frameBorder="0"
          ></iframe>
        </div>
      </div>

      <div className="container">
        <div className="column iframeDisplay">
          <a href="https://www.michigandaily.com/news/elections/2022-michigan-regular-and-special-election-results-democrat-flips-seat-in-gop-stronghold/">
            2022 Michigan regular and special election results: Democrat flips
            seat in GOP stronghold
          </a>
          <h5 className="text-normal">
            {" "}
            Made with Folium and Leaflet for Michigan's May 3rd special
            congressional election. Data sourced from Michigan's open data
            portal. HTML edited to remove tileset and add legend.
          </h5>
          <iframe
            title="May 3rd Ann Arbor special congressional election"
            className="iframeDisplay"
            src="https://michigandaily.github.io/may3-election/"
            frameBorder="0"
          ></iframe>
        </div>
      </div>

      <div className="container chart-row">
        <div className="half-column iframeDisplayChart">
          <a href="#">
            How has Ann Arbor's climate changed in the last two decades?
          </a>
          <h5 className="text-normal">
            {" "}
            I used Google Earth Engine, GDAL (a projection CLI-tool), ai2html,
            high spatial resolution Landsat 8 satellite data, and high temporal
            resolution MODIS Terra satellite data to compile and visualize a
            two-decade history of heat in Ann Arbor. I also analyzed the
            University of Michigan's building-by-building heat resilience by
            bounding surface heat data to building land use.
          </h5>
          <iframe
            title="Heat in Ann Arbor since 2001"
            className="iframeDisplayChart horizontal"
            src="https://michigandaily.github.io/weather-graphic-small-multiples/"
            frameBorder="0"
          ></iframe>
        </div>
        <div className="half-column">
          <StaticImage
            src="../../static/annarbormap.png"
            alt="Heat map of Ann Arbor"
          />
        </div>
      </div>

      {/* <div className="container chart-row">
        <div className="half-column iframeDisplayChart">
          <a href="https://www.michigandaily.com/statement/the-statement-2021-sex-survey/">
            {" "}
            The Statement 2021 Sex Survey
          </a>
          <h5 className="text-normal">
            {" "}
            Made with D3.js. Data sourced from the Daily's annual student survey
            for the Sex Edition.
          </h5>
          <iframe
            title="The Michigan Daily's 2021 Sex Edition"
            className="iframeDisplayChart"
            src="https://michigandaily.github.io/sex-edition-21-who/graphic/index.html"
            frameBorder="0"
          ></iframe>
        </div>
        <div className="half-column iframeDisplayChart">
          <a href="#">
            A look into the University's committment to carbon neutrality
          </a>
          <h5 className="text-normal">
            {" "}
            Made with D3.js. Data sourced from a University emissions report
            produced in 2020. Y-axis in tons of carbon dioxide. Accompanying
            story unpublished.
          </h5>
          <iframe
            title="University of Michigan C02 emissions"
            className="iframeDisplayChart"
            src="https://michigandaily.github.io/environment-emissions/graphic/index.html"
            frameBorder="0"
          ></iframe>
        </div>
      </div> */}

      <div className="container">
        <div className="column iframeDisplay">
          <a href="https://specials.michigandaily.com/orientation-22/">
            {" "}
            The 2022 Orientation Edition
          </a>
          <h5 className="text-normal">
            Made with React. Efficient resource allocation with an interaction
            observer, PDF rendering.
          </h5>
          <StaticImage
            src="../../static/orientation.jpg"
            alt="Michigan Daily 2022 Orientation Edition"
          />
        </div>
      </div>

      <div className="container" id="design-work">
      <div className="half-column iframeDisplayChart">
      <a href="#">
            Assorted design work
          </a>

      <h5 className="text-normal">
            {" "}
            Created with Adobe Illustrator for classwork and Tech 4 Social Good, a student organization at the University of Michigan. 
          </h5>

          <StaticImage className="smallImage"
            src="../../static/research.png"
            alt="Design for Tech 4 Social Good at the University of Michigan"
            />
          <StaticImage className="smallImage"
            src="../../static/resipsa.png"
            alt="Design for Tech 4 Social Good at the University of Michigan"
            />

        </div>

        <div className="half-column">
          <StaticImage
            src="../../static/t4sg.png"
            alt="Design for Tech 4 Social Good at the University of Michigan"
            />

        </div>
      </div>

      <Footer />
    </div>
  )
}
export default Home

export const Head = () => <SEO />
